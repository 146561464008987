<template>
  <ValidationProvider
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ valid, errors, touched }"
  >
    <b-form-group class="position-relative" :class="{ 'floating-label': floatingLabel }">
      <template v-if="!floatingLabel && label">
        <slot name="label">
          <label class="dual-label" v-if="typeof label === 'string'" :for="name"
            >{{ label }}
          </label>
          <label class="dual-label" v-else :for="name">
            <span>
              {{ label.main }}
            </span>
            <span>
              {{ label.secondary }}
            </span>
          </label>
        </slot>
      </template>

      <div v-if="masked" class="input-group">
        <div class="input-group-prepend" v-if="prepend">
          <span class="input-group-text bg-transparent" id="basic-addon1">{{
            prepend
          }}</span>
        </div>
        <ImaskInput
          class="form-control"
          :class="[
            errors[0] ? 'is-invalid' : valid && validate && touched ? 'is-valid' : null,
          ]"
          :value="value"
          v-on:input="onInput"
          v-bind="$attrs"
          :mask="mask"
          :placeholder="placeholder"
        />
      </div>

      <!-- <div v-else class="position-relative"> -->
      <b-form-input
        v-else
        :type="inputType"
        :value="value"
        v-on:input="onInput"
        v-bind="$attrs"
        :placeholder="floatingLabel ? ' ' : placeholder"
        :state="errors[0] ? false : valid && validate && touched ? true : null"
        :list="name"
        @change="$emit('change', $event)"
      >
      </b-form-input>
      <datalist :id="name" v-if="datalist.length">
        <option v-for="data in datalist" :value="data.value" :key="data.id" />
      </datalist>
      <img
        v-if="$attrs.type && $attrs.type === 'password' && floatingLabel"
        class="toggleShowPassword cursor-pointer"
        @click="toggleShowPassword"
        :src="require('../../../../assets/images/common/eye.svg')"
        alt="showpass"
      />
      <!-- </div> -->
      <label v-if="floatingLabel" :for="name">{{ placeholder }}</label>
      <b-form-invalid-feedback v-if="validate" id="inputLiveFeedback">
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
import { IMaskComponent } from "vue-imask";
export default {
  mixins: [inputMixin],
  components: {
    ImaskInput: IMaskComponent,
  },
  props: {
    mask: {
      type: [Array, Function],
      default() {
        return [];
      },
    },
    masked: {
      type: Boolean,
      default: false,
    },
    floatingLabel: {
      default: false,
      type: Boolean,
    },
    label: {
      type: [String, Object],
    },
    prepend: {
      type: String,
    },
    datalist: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      showpass: false,
    };
  },
  computed: {
    inputType() {
      // if (!this.$attrs.type || !this.showpass) return "text";
      if (this.$attrs.type && this.$attrs.type === "password" && !this.showpass)
        return "password";

      return this.$attrs.type ?? "text";
    },
  },
  methods: {
    toggleShowPassword() {
      this.showpass = !this.showpass;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggleShowPassword {
  position: absolute;
  right: 10px;
  top: 10px;
}

.dual-label,
label {
  color: #000929;
  font-weight: 500;
  font-family: "Plus Jakarta sans";
  font-size: 14px;
}
</style>
